<template>
  <div class="auth-wrapper auth px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            src="@/assets/images/logo/logo.png"
            alt="statuspro.club/"
            class="navbar-brand-full"
          /></b-link>

        <b-card-title class="mb-1 text-center">
          Начни зарабатывать бонусы прямо сейчас
        </b-card-title>
        <b-card-text
          v-if="step == 1"
          class="mb-2"
        >
          Для членства в statuspro.club и получения бонусов с продаж необходимо зарегистрироваться.
        </b-card-text>
        <b-card-text
          v-if="step == 2"
        >
          Остался последний шаг - введите код подтверждения, пришедший в sms на указанный номер телефона
        </b-card-text>

        <!-- form -->
        <validation-observer
          v-if="step == 1"
          ref="registerForm"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- username -->
            <b-form-group
              label="Имя:*"
              label-for="firstName"
            >
              <validation-provider
                #default="{ errors }"
                name="Имя"
                rules="required"
              >
                <b-form-input
                  id="firstName"
                  v-model="firstName"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Иван"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="phone"
              label="Телефон:*"
            >
              <validation-provider
                #default="{ errors }"
                name="телефон"
                rules="required|mobilephone"
              >
                <b-form-input
                  id="phone"
                  v-model="phone"
                  v-mask="'+7(###)###-##-##'"
                  :state="errors.length > 0 ? false:null"
                  placeholder="+7(910)100-10-10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                Согласие на <b-link>обработку персональных данных</b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Регистрация
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text
          v-if="step == 2"
          class="text-center mb-2"
        >
          <div>тел: {{ phone }} </div>
          <div>
            <b-link @click="step = 1">
              <span>cменить</span>
            </b-link>
          </div>
        </b-card-text>

        <sms-confirm
          v-if="step == 2"
          @send="OnSendRegister"
          @resendcode="onResendCode"
        />

        <b-card-text class="text-center mt-2">
          <span>Уже есть учетная запись? </span>
          <b-link :to="{ name:'auth-login' }">
            <span>Авторизация</span>
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mobilephone from '@/validations/mobileValidates'

import SmsConfirm from '@/components/SmsConfirm.vue'

import { sendConfirmationCode, register } from '@/application/authService'
import { fetchUserData } from '@/application/userService'
import { chekbasket } from '@/application/basketService'

import store from '@/store'
import router from '@/router'

localize('ru', ru)
extend('mobilephone', mobilephone)

export default {
  components: {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
    BImg,
    SmsConfirm,
  },
  setup() {
    const toast = useToast()

    const registerForm = ref(null)
    const phone = ref('')
    const firstName = ref('')
    const status = ref(false)
    const step = ref(1)

    const validationForm = async () => {
      try {
        if (!status.value) {
          throw new Error('Необходимо согласие на обработку персональных данных')
        }
        const isValid = registerForm.value.validate()
        if (isValid && status.value) {
          await sendConfirmationCode(phone.value, 'register')
          step.value = 2
        }
      } catch (error) {
        store.commit('app/SET_ERROR', error)
      }
    }

    const onResendCode = async () => {
      try {
        await sendConfirmationCode(phone.value, 'register')
      } catch (error) {
        store.commit('app/SET_ERROR', error)
      }
    }

    const OnSendRegister = async code => {
      try {
        if (!code || String(code).length !== 4) {
          throw new Error('Некорректный код подтверждения')
        }
        await register(phone.value, firstName.value, code)
        await fetchUserData()
        await chekbasket()
        await router.replace('/')
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Добро пожаловать ${store.state.user.userData.firstName}`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Вы успешно вошли в систему',
          },
        })
      } catch (error) {
        store.commit('app/SET_ERROR', error)
      }
    }

    return {
      registerForm,
      phone,
      firstName,
      status,
      required,
      step,

      validationForm,
      onResendCode,
      OnSendRegister,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
